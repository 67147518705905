import { Component } from "react";
import Onboarding from "./Onboarding";
import petmiLogo from '../images/petmiLogo.png';
import messageIcon from '../images/messageIcon.png';
import passwordIcon from '../images/passwordIcon.png';
import eyeClosed from '../images/eyeClosed.png';
import openEye from '../images/openEye.png';
import './style.css'
import { details } from "./Configs";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            newPassword: '',
            confirmNewPassword: '',
            isFilled: false,
            showPassword: false,
            showConfirmPassword: false,
        };
    }

    setNewPassword = async () => {
        const userEmail = localStorage.getItem(('resetUserEmail')) || '';
        const userOtp = localStorage.getItem(('resetUserOtp')) || '';
        try {
            const apiUrl = `http://${details.baseUrl}/user/password/reset/complete?emailAddress=${userEmail}&otp=${userOtp}&newPassword=${this.state.newPassword}`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            // console.log(jsonResponse);
            if (response.ok) {
                localStorage.removeItem('resetUserEmail');
                localStorage.removeItem('resetUserOtp');
                window.location.href = '/';
            } else {
                return document.getElementById('result').innerText = 'Error: Invalid OTP or Email';
            }

        } catch (error) {
            return document.getElementById('result').innerText = 'Something went wrong';
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    checkAllFieldsFilled = () => {
        const { newPassword, confirmNewPassword } = this.state;
        const isFilled = newPassword.trim() !== '' && confirmNewPassword.trim() !== '';
        this.setState({ isFilled });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.confirmNewPassword !== this.state.newPassword) {
            return document.getElementById('matchh').innerText = 'passwords do not match';
        }
        document.getElementById('matchh').innerText = '';
        this.setNewPassword();

    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    toggleConfirmPasswordVisibility = () => {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    };

    render() {
        const { newPassword, confirmNewPassword, isFilled } = this.state;
        const { showPassword, showConfirmPassword } = this.state;
        if (localStorage.getItem('resetUserEmail') == null || localStorage.getItem('resetUserEmail') == '' || localStorage.getItem('resetUserOtp') == null || localStorage.getItem('resetUserOtp') == '') {
            window.location.href = '/';
        }
        return (
            <div className="row w-100">
                <div className="col-6 first ">
                    <Onboarding />
                </div>


                <div className="col-6 second">
                    <div className="">
                        <img src={petmiLogo} className="img-thumbnail border-0 petmiLogo d-block" alt="..." />
                    </div>


                    <div className="mt-3 text-black ">
                        <h3 className="newPasswordSupText">Reset Password</h3>
                        <p className="newPasswordSubText">Ready to create new password? Please type something you’ll remember. </p>



                        <form className="mt-4" onSubmit={this.handleSubmit}>
                            <div className="py-1">
                                <p className=" text-danger text-center fs-5" id="result"></p>
                                <p className=" text-success text-center fs-5" id="result2"></p>
                            </div>
                            <div className="input-group flex-nowrap mt-3">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={passwordIcon} /></span>
                                <input name="newPassword" value={newPassword} onChange={this.handleInputChange} type={showPassword ? 'text' : 'password'} className="form-control p-3 passwordInput" placeholder="New Password" aria-label="Password" aria-describedby="addon-wrapping" />


                                <button type="button" className="btn border border-0 eyeButton" onClick={this.togglePasswordVisibility}>
                                    <img className="eyeIcon" src={showPassword ? openEye : eyeClosed} alt={showPassword ? 'Hide Password' : 'Show Password'} />
                                </button>

                            </div>

                            <div className="input-group flex-nowrap mt-3">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={passwordIcon} /></span>
                                <input name="confirmNewPassword" value={confirmNewPassword} onChange={this.handleInputChange} type={showConfirmPassword ? 'text' : 'password'} className="form-control p-3 passwordInput" placeholder="Confirm New Password" aria-label="Password" aria-describedby="addon-wrapping" />


                                <button type="button" className="btn border border-0 eyeButton" onClick={this.toggleConfirmPasswordVisibility}>
                                    <img className="eyeIcon" src={showConfirmPassword ? openEye : eyeClosed} alt={showConfirmPassword ? 'Hide Password' : 'Show Password'} />
                                </button>

                            </div>

                            <div className="">
                                <p className=" text-danger text-center fs-5" id="matchh"></p>
                            </div>

                            <div className="d-flex justify-content-center">
                                <button type="submit" className={`text-center btn mt-5 ps-5 pe-5 pt-2 pb-2 rounded-3 loginBtn ${isFilled ? 'filled' : ''}`} disabled={!isFilled}>
                                    Reset
                                </button>
                            </div>


                        </form>
                    </div>



                </div>
            </div>
        );
    }
}

export default ResetPassword;