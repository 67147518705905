import { Component } from "react";
import Onboarding from "./Onboarding";
import petmiLogo from '../images/petmiLogo.png';
import messageIcon from '../images/messageIcon.png';
import passwordIcon from '../images/passwordIcon.png';
import eyeClosed from '../images/eyeClosed.png';
import openEye from '../images/openEye.png';
import { details } from "./Configs";
import './style.css'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isFilled: false,
            showPassword: false,
        };
    }


    fetchData = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/user/authenticate?emailAddress=${this.state.email}&password=${this.state.password}&role=admin`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const jsonResponse = await response.json();          //if there's an error, un-comment this and console.log(jsonResponse) if you want to see the message error
            // console.log(jsonResponse);
            // console.log(this.state.email);
            // console.log(this.state.password);

            if (response.ok) {
                localStorage.setItem('user', JSON.stringify(jsonResponse.data))
                window.location.href = '/manage/ads';
            } else {
                return document.getElementById('result').innerText = 'Error: Invalid Login details';
            }
        } catch (error) {
            // Handle network errors or other exceptions
            return document.getElementById('result').innerText = 'Something went wrong';
        }
    };

    redirectToAnotherComponent = () => {
        // MIGHT NEED THIS LATER
        // const { history } = this.props;
        // // Use history.push to navigate to another route
        // history.push('/', { state: { ...this.state } });


        // window.location.href = '/home';
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    checkAllFieldsFilled = () => {
        const { email, password } = this.state;
        const isFilled = email.trim() !== '' && password.trim() !== '';
        this.setState({ isFilled });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // this.redirectToAnotherComponent();
        this.fetchData();

    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };


    render() {
        const { email, password, isFilled } = this.state;
        const { showPassword } = this.state;

        if (localStorage.getItem('user')) {
            localStorage.removeItem('user');
        }

        return (
            <div className="row w-100">
                <div className="col-6 first ">
                    <Onboarding />
                </div>



                <div className="col-6 second">
                    <div className="">
                        <img src={petmiLogo} className="img-thumbnail border-0 petmiLogo d-block" alt="..." />
                    </div>


                    <div className="mt-3 text-black ">
                        <h3 className="loginSupText">Welcome Back</h3>
                        <p className="loginSubText">Go ahead and sign in. Get access to your Petmi account!</p>


                        <div>
                            <p className=" text-danger text-center fs-5" id="result"></p>
                        </div>
                        <form className="mt-4" onSubmit={this.handleSubmit}>
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={messageIcon}></img></span>
                                <input name="email" value={email} onChange={this.handleInputChange} type="text" className="form-control p-3" placeholder="Email Address" aria-label="email" aria-describedby="addon-wrapping" />
                            </div>
                            <div className="input-group flex-nowrap mt-3">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={passwordIcon} /></span>
                                <input name="password" value={password} onChange={this.handleInputChange} type={showPassword ? 'text' : 'password'} className="form-control p-3 passwordInput" placeholder="Password" aria-label="Password" aria-describedby="addon-wrapping" />


                                <button type="button" className="btn border border-0 eyeButton" onClick={this.togglePasswordVisibility}>
                                    <img className="eyeIcon" src={showPassword ? openEye : eyeClosed} alt={showPassword ? 'Hide Password' : 'Show Password'} />
                                </button>

                            </div>


                            <div className="d-flex justify-content-end mt-2 forgotPassword">
                                <p><a href="/forgot/password" className="forgotPasswordLink">Forgot password?</a></p>
                            </div>

                            <div className="d-flex justify-content-center">
                                <button type="submit" className={`text-center btn mt-5 ps-5 pe-5 pt-2 pb-2 rounded-3 loginBtn ${isFilled ? 'filled' : ''}`} disabled={!isFilled}>
                                    Sign In
                                </button>
                            </div>


                        </form>
                    </div>



                </div>
            </div>
        )
    }
}

export default Login;