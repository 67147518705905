import { Component } from "react"
import Onboarding from "./Onboarding"
import './style.css'
import petmiLogo from '../images/petmiLogo.png';

class VerifyOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            box1: '',
            box2: '',
            box3: '',
            box4: '',
            isFilled: false,
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    checkAllFieldsFilled = () => {
        const { box1, box2, box3, box4 } = this.state;
        const isFilled = box1.trim() !== '' && box2.trim() !== '' && box3.trim() !== '' && box4.trim() !== '';
        this.setState({ isFilled });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = '/verify/otp';
    };



    render() {
        const { box1, box2, box3, box4, isFilled } = this.state;
        return (
            <div className="row w-100">
                <div className="col-6 first">
                    <Onboarding />
                </div>


                <div className="col-6 second">
                    <div className="">
                        <img src={petmiLogo} className="img-thumbnail border-0 petmiLogo d-block" alt="..." />
                    </div>


                    <div className="mt-3 text-black ">
                        <h3 className="VerifyOtpSupText">Verify OTP</h3>
                        <p className="VerifyOtpSubText">Awesome, we have sent you a four digit one time password to your email at <span className="fw-bold">yolandafarida334@gmail.com</span> </p>


                        <form onSubmit={this.handleSubmit}>
                            <div className="mb-3 d-flex justify-content-center align-items-center " >
                                <input name="box1" value={box1} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <input name="box2" value={box2} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputPassword1" />
                                <input name="box3" value={box3} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputPassword1" />
                                <input name="box4" value={box4} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputPassword1" />
                            </div>

                            <div className={`text-center ${isFilled ? 'd-none' : ''}`}>
                                <p className="verifyOtpTime">00:03 seconds</p>
                                <p className="noOtp">Didn’t receive code? <span className="resend"><a href="">Re-send</a></span> </p>
                            </div>

                            <div className="d-flex justify-content-center ">
                                <button type="submit" className={`verifyOtpButton text-center btn mt-3 ps-5 pe-5 pt-2 pb-2 rounded-3 ${isFilled ? 'verifyOtpButtonFilled' : ''}`}>
                                    Verify OTP
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default VerifyOtp