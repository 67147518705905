import { Component } from "react";
import Onboarding from "./Onboarding";
import petmiLogo from '../images/petmiLogo.png';
import messageIcon from '../images/messageIcon.png';
import passwordIcon from '../images/passwordIcon.png';
import eyeClosed from '../images/eyeClosed.png';
import openEye from '../images/openEye.png';
import './style.css'
import { details } from "./Configs";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isFilled: false,
            showPassword: false,
        };
    }

    sendOtp = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/user/password/reset/initiate?emailAddress=${this.state.email}`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            // console.log(jsonResponse);
            if (response.ok) {
                const userEmail = this.state.email;
                localStorage.setItem('resetUserEmail', userEmail.trim());
                window.location.href = '/password/reset/otp';
            } else {
                return document.getElementById('result').innerText = 'Error: Email not found in our records';
            }

        } catch (error) {
            return document.getElementById('result').innerText = 'Something went wrong';
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    checkAllFieldsFilled = () => {
        const { email } = this.state;
        const isFilled = email.trim() !== '';
        this.setState({ isFilled });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.sendOtp();

    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };
    render() {
        const { email, isFilled } = this.state;
        return (
            <div className="row w-100">
                <div className="col-6 first ">
                    <Onboarding />
                </div>

                <div className="col-6 second">
                    <div className="">
                        <img src={petmiLogo} className="img-thumbnail border-0 petmiLogo d-block" alt="..." />
                    </div>


                    <div className="mt-3 text-black ">
                        <h3 className="forgetPasswordSupText">Forgot Password</h3>
                        <p className="forgetPasswordSubText">Don’t worry! It happens. Please enter the email associated with your account. A link will be sent to your mail to reset a new password</p>

                        <form className="mt-5 pt-3" onSubmit={this.handleSubmit}>
                            <div>
                                <p className=" text-danger text-center fs-5" id="result"></p>
                            </div>
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={messageIcon}></img></span>
                                <input name="email" value={email} onChange={this.handleInputChange} type="text" className="form-control p-3" placeholder="Email Address" aria-label="email" aria-describedby="addon-wrapping" />
                            </div>


                            <div className="d-flex justify-content-center">
                                <button type="submit" className={`text-center btn mt-5 ps-5 pe-5 pt-2 pb-2 rounded-3 resetPasswordBtn ${isFilled ? 'filled' : ''}`} >
                                    Reset Password
                                </button>
                            </div>


                        </form>
                    </div>



                </div>
            </div>



        );
    }
}

export default ForgotPassword;