import React, { useState, useEffect, Component } from 'react';
import dogCat from '../images/dogCat.png';
import dog from '../images/dog.png';
import cat from '../images/cat.png';
import './style.css'

class Onboarding extends Component {
    render() {
        return (
            
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={dogCat} className="d-block mx-auto" alt="..." />
                        <div className="d-none d-md-block text-center text-white ">
                            <h5 className="onboardingHeading mb-3 mt-5">Connect with pet lovers around you</h5>
                            <p className="onboardingText ">We've created a community where pet lovers can connect <br/> from anywhere.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={dog} className="d-block onboardingImage mx-auto" alt="..." />
                        <div className="d-none d-md-block text-center text-white  ">
                            <h5 className="onboardingHeading mb-3 mt-5">Learn more about your pet</h5>
                            <p className="onboardingText">Take the best care of your pet with useful pet <br/> care tips- learn  more from experts in the field</p>
                        </div>
                    </div>
                    <div className="carousel-item ">
                        <img src={cat} className="d-block onboardingImage mx-auto catImg" alt="..." />
                        <div className="d-none d-md-block text-center text-white ">
                            <h5 className="onboardingHeading mb-3 ">Explore our Marketplace</h5>
                            <p className="onboardingText ">Buy or sell pets or pet products from the <br/> comfort of your home.</p>
                        </div>
                    </div>
                </div>

                <div className="pt-5">
                    <div className="carousel-indicators ">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                </div>


            </div>
        );
    }
}


export default Onboarding;
