import { Component } from "react"
import Onboarding from "./Onboarding"
import './style.css'
import petmiLogo from '../images/petmiLogo.png';
import { details } from "./Configs";

class OtpResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            box1: '',
            box2: '',
            box3: '',
            box4: '',
            isFilled: false,
        };
    }


    verifyOtp = async () => {
        const userEmail = localStorage.getItem(('resetUserEmail')) || '';
        const otpp = this.state.box1 + this.state.box2 + this.state.box3 + this.state.box4;
        try {
            const apiUrl = `http://${details.baseUrl}/user/otp/verify?emailAddress=${userEmail}&otp=${otpp}`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            // console.log(jsonResponse);
            if (response.ok) {
                localStorage.setItem('resetUserOtp', otpp)
                window.location.href = '/reset/password/new';
            } else {
                return document.getElementById('result').innerText = 'Error: Invalid OTP';
            }

        } catch (error) {
            return document.getElementById('result').innerText = 'Something went wrong';
        }
    }



    resendOtp = async () => {
        const userEmail = localStorage.getItem(('resetUserEmail')) || '';
        try {
            const apiUrl = `http://${details.baseUrl}/user/password/reset/initiate?emailAddress=${userEmail}`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            // console.log(jsonResponse);
            if (response.ok) {
                return document.getElementById('result2').innerText = 'OTP sent successfully';
            } else {
                return document.getElementById('result').innerText = 'Error: Email not found in our records';
            }

        } catch (error) {
            return document.getElementById('result').innerText = 'Something went wrong';
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    checkAllFieldsFilled = () => {
        const { box1, box2, box3, box4 } = this.state;
        const isFilled = box1.trim() !== '' && box2.trim() !== '' && box3.trim() !== '' && box4.trim() !== '';
        this.setState({ isFilled });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.verifyOtp();
    };


    handleResendOtp = (e) => {
        e.preventDefault();
        this.resendOtp();
    };


    render() {
        const { box1, box2, box3, box4, isFilled } = this.state;
        if (localStorage.getItem('resetUserEmail') == null || localStorage.getItem('resetUserEmail') == '') {
            window.location.href = '/';
        }
        return (
            <div className="row w-100">
                <div className="col-6 first">
                    <Onboarding />
                </div>


                <div className="col-6 second">
                    <div className="">
                        <img src={petmiLogo} className="img-thumbnail border-0 petmiLogo d-block" alt="..." />
                    </div>


                    <div className="mt-3 text-black ">
                        <h3 className="VerifyOtpSupText">Verify OTP</h3>
                        <p className="VerifyOtpSubText">Awesome, we have sent you a four digit one time password to your email at <span className="fw-bold">{localStorage.getItem(('resetUserEmail')) || ''}</span> </p>
                        <div className="py-4">
                            <p className=" text-danger text-center fs-5" id="result"></p>
                            <p className=" text-success text-center fs-5" id="result2"></p>
                        </div>


                        <form onSubmit={this.handleSubmit}>
                            <div className="mb-3 d-flex justify-content-center align-items-center " >
                                <input name="box1" value={box1} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <input name="box2" value={box2} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputPassword1" />
                                <input name="box3" value={box3} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputPassword1" />
                                <input name="box4" value={box4} onChange={this.handleInputChange} maxLength={1} type="text" className=" verifyOtpInput" id="exampleInputPassword1" />
                            </div>

                            <div className={`text-center ${isFilled ? 'd-none' : ''}`}>
                                {/* <p className="verifyOtpTime">00:03 seconds</p> */}
                                <p className="noOtp">Didn’t receive code? <span className="resend"><a onClick={this.handleResendOtp} href="#">Re-send</a></span> </p>
                            </div>

                            <div className="d-flex justify-content-center ">
                                <button type="submit" className={`verifyOtpButton text-center btn mt-3 ps-5 pe-5 pt-2 pb-2 rounded-3 ${isFilled ? 'verifyOtpButtonFilled' : ''}`} disabled={!isFilled}>
                                    Verify OTP
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default OtpResetPassword