import { Component } from "react"
import Onboarding from "./Onboarding";
import VerifyOtp from "./VerifyOtp";
import petmiLogo from '../images/petmiLogo.png';
import messageIcon from '../images/messageIcon.png';
import personIcon from '../images/personIcon.png';
import passwordIcon from '../images/passwordIcon.png';
import eyeClosed from '../images/eyeClosed.png';
import openEye from '../images/openEye.png';
import './style.css'
import { withRouter } from "react-router-dom";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            fullName: '',
            password: '',
            isFilled: false,
            showPassword: false,
        };
    }

    redirectToAnotherComponent = () => {
        // MIGHT NEED THIS LATER
        // const { history } = this.props;
        // // Use history.push to navigate to another route
        // history.push('/VerifyOtp', { state: { ...this.state } });

        window.location.href = '/verify/otp';
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    checkAllFieldsFilled = () => {
        const { email, fullName, password } = this.state;
        const isFilled = email.trim() !== '' && fullName.trim() !== '' && password.trim() !== '';
        this.setState({ isFilled });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.redirectToAnotherComponent();

    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };
    render() {
        const { email, fullName, password, isFilled } = this.state;
        const { showPassword } = this.state;
        return (
            <div className="row w-100">
                <div className="col-6 first ">
                    <Onboarding />
                </div>


                <div className="col-6 second">
                    <div className="">
                        <img src={petmiLogo} className="img-thumbnail border-0 petmiLogo d-block" alt="..." />
                    </div>


                    <div className="mt-3 text-black ">
                        <h3 className="createAccountSupText">Create Account</h3>
                        <p className="createAccountSubText">Go ahead and sign up, let everyone know how awesome of a pet parent you are!</p>



                        <form className="mt-4" onSubmit={this.handleSubmit}>
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={messageIcon}></img></span>
                                <input name="email" value={email} onChange={this.handleInputChange} type="text" className="form-control p-3" placeholder="Email Address" aria-label="email" aria-describedby="addon-wrapping" />
                            </div>
                            <div className="input-group flex-nowrap mt-3 mb-3">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={personIcon}></img></span>
                                <input name="fullName" value={fullName} onChange={this.handleInputChange} type="text" className="form-control p-3" placeholder="Full Name" aria-label="Full Name" aria-describedby="addon-wrapping" />
                            </div>
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping"><img className="formIcons" src={passwordIcon} /></span>
                                <input name="password" value={password} onChange={this.handleInputChange} type={showPassword ? 'text' : 'password'} className="form-control p-3 passwordInput" placeholder="Password" aria-label="Password" aria-describedby="addon-wrapping" />

                                <button type="button" className="btn border border-0 eyeButton" onClick={this.togglePasswordVisibility}>
                                    <img className="eyeIcon" src={showPassword ? openEye : eyeClosed} alt={showPassword ? 'Hide Password' : 'Show Password'} />
                                </button>
                            </div>

                            <div className="d-flex justify-content-center">
                                <button type="submit" className={`text-center btn mt-5 ps-5 pe-5 pt-2 pb-2 rounded-3 verifyEmailBtn ${isFilled ? 'filled' : ''}`} >
                                    Verify Email Address
                                </button>
                            </div>

                            <div>
                                <p className="text-center mt-3 lastText">
                                    Already have an account? <span className="signInText"><a href="./">Sign in</a> </span>
                                </p>
                            </div>

                        </form>
                    </div>



                </div>
            </div>
        )
    }
}

export default withRouter(Signup);