import { Component } from "react";
import petmiLogo from '../images/petmiLogo.png';
import adsIcon from '../images/adsIcon.png';
import articleIcon from '../images/articlesIcon.png';
import logoutIcon from '../images/logoutIcon.png';
import sideDog from '../images/sideDog.png';
import notificationIcon from '../images/notificationIcon.png';
import personAvatar from '../images/personAvatar.png';
import dogRound from "../images/dogRound.png";
import adsBigDog from "../images/adsBigDog.png";
import dog from '../images/dog.png';
import dogCat from '../images/dogCat.png';

class NotFound extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <div className=" ">
                <div className="row g-0 ">
                    {/* Sidebar  */}
                    <nav id="sidebar" className={`col-2 d-md-block border border-1 comp-two `} >
                        <div className="text-center mt-3">
                            <img src={petmiLogo} alt="Logo" className="img-fluid  petmiLogo2 ms-3 mt-4 " />
                        </div>

                        <ul className="nav flex-column mt-3 mb-5">
                            <li className="nav-item w-100 activeSideLink">
                                <a className="nav-link active sideLink" href="/manage/ads" >
                                    <img src={adsIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Home
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link sideLink" href="#">
                                    Posts
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link sideLink " href="/manage/ads">
                                    <img src={articleIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Home
                                </a>
                            </li>
                            <li className="nav-item mb-5">
                                <a className="nav-link sideLink" href="/manage/ads" >
                                    <img src={logoutIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Home
                                </a>
                            </li>
                        </ul>

                        <img src={sideDog} alt="Bottom Image" className="img-fluid ms-1 mt-5 pt-5" />
                    </nav>

                    {/* <!-- Page content --> */}
                    <main className="col-9 mt-3 ">
                        <div className={`row gx-5  navRow ms-3 comp-two `}>
                            <div className="col-5 mt-3">
                                <div className="input-group">
                                    <input type="text" className="form-control searchInput" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                                    <button className="btn btn-outline-secondary searchIconBtn pe-4" type="button" id="search-addon">
                                        <i className="bi bi-search searchIcon"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-6 d-flex justify-content-end ps-4 mt-3 outterRightNavDiv">
                                <div className="notificationIconDiv me-5">
                                    <img src={notificationIcon} alt="Notification Icon" className="img-fluid notificationIcon" />
                                    <span className=" position-relative">
                                        <i className="bi notificationDot bi-dot notigi position-absolute top-0 start-100 translate-middle pe-2 pt-2"></i>
                                    </span>
                                </div>



                            </div>
                        </div>
                        <div className={`comp-two `}>
                            <hr className="navLine " />
                        </div>

                        <div className={`container ms-5 comp-two `}>
                            <div className="d-flex justify-content-between">
                                <h3 className="text-danger">Error 404: Page not found</h3>

                            </div>

                            <div className="error404ImgDiv">
                                <img src={dogCat} className="img error404Img img-thumbnail border border-0"></img>

                            </div>
                            <div className="text-center mt-4 text-danger">
                                <h3>Error 404: </h3>
                                <p>Woof! This page seems to have run off on a squirrel-chasing mission. Our digital leash couldn't hold it. Please return to the homepage and fetch a different link.</p>
                        </div>

                </div>




            </main>
                </div >
            </div >
        );
    }
}

export default NotFound;