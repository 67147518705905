
const stagingIp = '34.68.137.87:8080';
const apiVersion = '/api/v1'

export const details = {
    baseUrl: stagingIp + apiVersion,
    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem(('user')))?.token || '',
    CloudinaryApiKey : '427118237162398',
    CloudinaryApiSecret : '8i_3_C9dwvo1dQS3PdfXPzBE_ls',
    CloudName: 'dk0heqgq3',
    uploadPreset: 'resource_images'
} 
