import { BrowserRouter as Router, Route } from "react-router-dom";
import React, { Component } from 'react';
import Signup from "./components/Signup";
import VerifyOtp from "./components/VerifyOtp";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ManageAds from "./components/ManageAds";
import ManageArticlesPosts from "./components/ManageArticlesPosts";
import OtpResetPassword from "./components/OtpResetPassword";
import NotFound from "./components/NotFound";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/"  component={Login} />
          <Route path="/verify/otp" component={VerifyOtp} />
          <Route path="/signup" component={Signup} />
          <Route path="/forgot/password" component={ForgotPassword} />
          <Route path="/reset/password/new" component={ResetPassword} />
          <Route path="/manage/ads" component={ManageAds} />
          <Route path="/manage/resources" component={ManageArticlesPosts} />
          <Route path="/password/reset/otp" component={OtpResetPassword} />
          <Route component={NotFound} />
          </Switch>
      </Router>
    </div>
  );
}

export default App;
