import { Component } from "react";
import petmiLogo from '../images/petmiLogo.png';
import adsIconBlack from '../images/adsIconBlack.png';
import articleIconWhite from '../images/articlesIconWhite.png';
import logoutIcon from '../images/logoutIcon.png';
import sideDog from '../images/sideDog.png';
import notificationIcon from '../images/notificationIcon.png';
import personAvatar from '../images/personAvatar.png';
import dogRound from "../images/dogRound.png";
import adsBigDog from "../images/adsBigDog.png";
import petmiRoundLogo from "../images/petmiRoundLogo.png";
import dogArticlePopup from "../images/dogArticlePopup.png";
import { details } from "./Configs";



class ManageArticlesPosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            showNewArticlePostPopUp: false,
            selectedFile: null,
            showTags: false,
            tagsData: [],
            resourcesPage: 0,
            resourcesData: [],
            permanentresourcesData: [],
            showresources: false,
            resourceDetails: [],
            showResourceDetails: false,
            currentPage: 1,
            resourceStatusList: [],
            showResourceStatusDropdown: false,
            showResourceType: false,
            resourceTypeList: [],
            isFilled: false,
            newResourceImageisFilled: false,
            newResourceTagisFilled: false,
            successImageUpload: true,
            newResourceHeroImage: '',
            title: '',
            description: '',
            type: '',
            newResourceStatus: '',
            tags: [],
            searchValue: '',
            isSearchValueChanged: false,
            searchData: [],
            clickOnNewResourceTags: 1,
            disableNewResourcesForm: false,
        };
    }

    componentDidMount() {
        this.fetchResourcesList();
        this.fetchResourceDropdown();
    }

    fetchResourcesList = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources?page=${this.state.resourcesPage}&pageSize=10`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const resourcesList = jsonResponse.data;
            // console.log(jsonResponse);
            this.setState({ resourcesData: resourcesList });
            this.setState({ permanentresourcesData: resourcesList });
            // this.setState({ dontRunFetchAds: true })

            if (response.ok) {
                return this.setState({ showresources: true })
            } else {
                return this.setState({ showresources: false })
            }
        } catch (error) {
            // this.setState({ dontRunFetchAds: true })
            return this.setState({ showresources: false })

        }
    };

    fetchArticlesPostDetails = async (resourceId) => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources/${resourceId}`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const resourceList = jsonResponse.data;
            // console.log(jsonResponse);
            this.setState({ resourceDetails: resourceList }, () => {
                if (response.ok) {
                    return this.setState({ showResourceDetails: true })
                } else {
                    return this.setState({ showResourceDetails: false })
                }
            });



        } catch (error) {
            return this.setState({ showResourceDetails: false })
        }
    };

    fetchData = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/tags`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const tagsDataa = jsonResponse.data;
            this.setState({ tagsData: tagsDataa });
            // console.log(this.state.tagsData);

            if (response.ok) {
                return this.setState({ showTags: true })
            } else {
                return this.setState({ showTags: false })
            }
        } catch (error) {
            return this.setState({ showTags: false })

        }
    };

    fetchResourceDropdown = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources/states`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const statusList = jsonResponse.data;
            // console.log(jsonResponse);
            this.setState({ resourceStatusList: statusList });

            if (response.ok) {
                return this.setState({ showResourceStatusDropdown: true })
            } else {
                return this.setState({ showResourceStatusDropdown: false })
            }
        } catch (error) {
            console.log(error)

        }
    };


    updateResourceStatus = async (adId, statusId) => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources/${adId}`;


            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
                body: statusId
            });
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            // console.log(jsonResponse);
            if (response.ok) {
                document.getElementById("resourceStatusChangeError").style.display = 'none';
                this.handleCloseNewArticlePostPopUp();
            } else {
                setTimeout(() => {
                    document.getElementById("resourceStatusChangeError").style.display = 'block';
                }, 1000);
            }



        } catch (error) {
            setTimeout(() => {
                document.getElementById("resourceStatusChangeError").style.display = 'block';
            }, 1000);
        }
    };

    fetchResourceType = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resource/type`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const typeList = jsonResponse.data;
            // console.log(jsonResponse);
            this.setState({ resourceTypeList: typeList });

            if (response.ok) {
                return this.setState({ showResourceType: true })
            } else {
                return this.setState({ showResourceType: false })
            }
        } catch (error) {
            console.log(error)

        }
    };


    createResource = async () => {
        const requestBody = {
            "title": this.state.title,
            "bodyText": this.state.description,
            "heroPhotoUrl": this.state.newResourceHeroImage,
            "resourceTypeId": this.state.type,
            "resourceStatusId": this.state.newResourceStatus,
            "userId": JSON.parse(localStorage.getItem(('user'))).id,
            "tagIds": this.state.tags
        }
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
                body: JSON.stringify(requestBody)
                // console.log(body)
            });

            // console.log(JSON.stringify(requestBody) );
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            console.log(jsonResponse);
            if (response.ok) {
                this.handleCloseNewArticlePostPopUp();
            } else {

            }

        } catch (error) {

        }
    };

    deleteResource = async (resourceId) => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources/${resourceId}`;


            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const responseData = jsonResponse.data;
            // console.log(jsonResponse);
            if (response.ok) {
                this.handleCloseNewArticlePostPopUp();
            } else {
            }

        } catch (error) {
        }
    };

    fetchSearchList = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/resources/search?page=${this.state.resourcesPage}&pageSize=10&term=${this.state.searchValue}`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const searchList = jsonResponse.data;
            console.log(jsonResponse);
            // this.setState({ dontRunFetchAds: true })

            if (response.ok) {
                if (this.state.isSearchValueChanged) {
                    this.setState({ resourcesData: searchList });
                } else {
                }

            } else {
                // console.log("response not ok")
            }
        } catch (error) {
            console.log(error)

        }
    };

    handleUploadImage = async () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('upload_preset', `${details.uploadPreset}`);
        data.append('cloud_name', `${details.CloudName}`);
        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/${details.CloudName}/upload`, {
                method: 'POST',
                body: data
            });
            const file = await response.json();
            const imageUrl = file.secure_url;


            if (file) {
                this.setState({ successImageUpload: true });
                this.setState({ newResourceHeroImage: imageUrl }, () => {
                    this.createResource();
                });

            }
            else {
                this.setState({ successImageUpload: false });

            }
        } catch (error) {
            console.log(error);
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, this.checkAllFieldsFilled);
    };

    handleNewResourceTags = (e) => {
        if (this.state.tags.includes(e.target.getAttribute('value'))) {
            e.target.style.backgroundColor = '#DADADA';
            e.target.style.color = '#c1bdbd';
            const newArray = this.state.tags.filter(item => item !== e.target.getAttribute('value'));
            this.setState({ tags: newArray }) 
            // console.log(this.state.tags);
        } else {
            e.target.style.backgroundColor = '#fdf0d7';
            e.target.style.color = '#ED9D02';
            this.setState({ tags: [...this.state.tags, e.target.getAttribute('value')] }, this.setState({ newResourceTagisFilled: true }));
            // console.log(this.state.tags);
        }


    }

    checkAllFieldsFilled = () => {
        const { image, title, description, type, newResourceStatus, tags } = this.state;
        const isFilled = title.trim() !== '' && description.trim() !== '' && type.trim() !== '' && newResourceStatus.trim() !== '';
        this.setState({ isFilled });
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({disableNewResourcesForm: true});
        document.getElementById('addArticlePostBtnn').disabled = true;
        this.handleUploadImage();
    }



    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.setState({ selectedFile: file });
        this.setState({ newResourceImageisFilled: true });


    };

    handleNewArticlePostPopUp = () => {
        this.fetchData();
        this.fetchResourceType();
        this.setState({ showNewArticlePostPopUp: true });
    };

    handleCloseNewArticlePostPopUp = () => {
        window.location.href = '/manage/resources' // I added this so that the create article form can reset
        // this.setState({ showNewArticlePostPopUp: false }); // I'm purposely leaving this here
    };

    handleShowPopUp = (resourceId) => {
        this.fetchArticlesPostDetails(resourceId);
        this.setState({ showPopUp: true });
    };

    handleClosePopUp = () => {
        this.setState({ showPopUp: false });
        window.location.href = '/manage/resources';
    }

    handleLogout = () => {
        localStorage.removeItem('user');
        window.location.href = '/';
    }

    handleLeftChevronButton = () => {
        this.setState({ currentPage: this.state.currentPage - 1 })
        this.setState({ resourcesPage: this.state.resourcesPage - 1 }, () => {
            this.fetchResourcesList();
        })

    }

    handleRightChevronButton = () => {
        this.setState({ currentPage: this.state.currentPage + 1 })
        this.setState({ resourcesPage: this.state.resourcesPage + 1 }, () => {
            this.fetchResourcesList();
        })

    }

    handleSearchInputChange = (e) => {
        const newValue = e.target.value;
        this.setState({
            searchValue: newValue,
        }, () => {
            newValue.length >= 1 ?
                this.setState({
                    isSearchValueChanged: true,
                }, () => { this.fetchSearchList(); })

                : this.setState({
                    resourcesData: this.state.permanentresourcesData,
                    isSearchValueChanged: false,

                })


        });
    }


    handleCloseSearchResult = () => {
        this.setState({
            resourcesData: this.state.permanentresourcesData,
            isSearchValueChanged: false,
            searchValue: '',

        })
    }


    // THIS IS THE CODE FOR FILTERING BETWEEN WEEK, MONTH AND DAY. MIGHT NEED IT LATER

    // changeToDay = () => {
    //     var innerButton = document.getElementById('dayBtn');

    //     // Remove the timeframeActive class from other spans
    //     var removeClass = document.querySelector('.timeframeActive');
    //     removeClass.classList.remove('timeframeActive');

    //     var parentElement = innerButton.parentElement;
    //     parentElement.classList.add('timeframeActive');
    // }

    // changeToWeek = () => {
    //     var innerButton = document.getElementById('weekBtn');

    //     // Remove the timeframeActive class from other spans
    //     var removeClass = document.querySelector('.timeframeActive');
    //     removeClass.classList.remove('timeframeActive');

    //     var parentElement = innerButton.parentElement;
    //     parentElement.classList.add('timeframeActive');
    // }

    // changeToMonth = () => {
    //     var innerButton = document.getElementById('monthBtn');

    //     // Remove the timeframeActive class from other spans
    //     var removeClass = document.querySelector('.timeframeActive');
    //     removeClass.classList.remove('timeframeActive');

    //     var parentElement = innerButton.parentElement;
    //     parentElement.classList.add('timeframeActive');
    // }

    render() {
        // console.log(JSON.parse(localStorage.getItem(('user'))))
        const { showPopUp } = this.state;
        const { showNewArticlePostPopUp } = this.state;
        const { selectedFile } = this.state;
        const { tagsData } = this.state;
        const storedUser = JSON.parse(localStorage.getItem(('user')));
        const { image, title, description, type, tags } = this.state;
        if (!storedUser) {
            window.location.href = '/';
        }
        // this.createResource();
        return (
            <div className="">
                <div className="row  g-0 ">
                    {/* Sidebar  */}
                    <nav id="sidebar" className={` col-2 border border-1 comp-two ${showPopUp || showNewArticlePostPopUp ? 'manageAdsDiv' : ''}`} >
                        <div className="text-center mt-3">
                            <img src={petmiLogo} alt="Logo" className="img-fluid  petmiLogo2 ms-3 mt-4 " />
                        </div>

                        <ul className="nav flex-column mt-3 mb-5">
                            <li className="nav-item w-100 ">
                                <a className="nav-link active sideLink" href="/manage/ads">
                                    <img src={adsIconBlack} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Ads
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                    <a className="nav-link sideLink" href="#">
                                        Posts
                                    </a>
                                </li> */}
                            <li className="nav-item activeSideLink">
                                <a className="nav-link sideLink" href="/manage/resources">
                                    <img src={articleIconWhite} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Articles/Posts
                                </a>
                            </li>
                            <li className="nav-item mb-5">
                                <a className="nav-link sideLink" href="#" onClick={this.handleLogout}>
                                    <img src={logoutIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Logout
                                </a>
                            </li>
                        </ul>

                        <img src={sideDog} alt="Bottom Image" className="img-fluid ms-1 mt-5 pt-5" />
                    </nav>

                    {/* <!-- Page content --> */}
                    <main className="col-9 mt-3 ">
                        <div className={`row gx-5  navRow ms-3 comp-two ${showPopUp || showNewArticlePostPopUp ? 'manageAdsDiv' : ''}`}>
                            <div className="col-5 mt-3">
                                <div className="input-group">
                                    <input value={this.state.searchValue} onChange={this.handleSearchInputChange} type="text" className="form-control searchInput" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                                    <button className="btn btn-outline-secondary searchIconBtn pe-4" type="button" id="search-addon">
                                        <i className="bi bi-search searchIcon"></i>
                                    </button>

                                    {this.state.isSearchValueChanged ?
                                        <button onClick={this.handleCloseSearchResult} className=" ps-3 closeSearchResult"><i class="bi bi-x-lg"></i></button>
                                        : null
                                    }
                                </div>
                            </div>

                            <div className="col-6 d-flex justify-content-end ps-4 mt-3 outterRightNavDiv">
                                <div className="notificationIconDiv me-5">
                                    <img src={notificationIcon} alt="Notification Icon" className="img-fluid notificationIcon" />
                                    <span className=" position-relative">
                                        <i className="bi notificationDot bi-dot notigi position-absolute top-0 start-100 translate-middle pe-2 pt-2"></i>
                                    </span>
                                </div>

                                <div className="rightNav mt-4 ">
                                    <img src={storedUser.profileImageUrl} alt="Logout Icon" className="img-fluid personAvatar" />
                                    <div className="rigtNavInnerDiv" >
                                        <span className="personName">{storedUser.firstName}</span>
                                        <span className="personRole">Admin</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`navlineDiv comp-two ${showPopUp || showNewArticlePostPopUp ? 'manageAdsDiv' : ''}`}>
                            <hr id="navLinee" className="navLine " />
                        </div>

                        <div className={`container ms-5 comp-two ${showPopUp || showNewArticlePostPopUp ? 'manageAdsDiv' : ''}`}>
                            <div className="d-flex justify-content-between ">
                                <h3>Article/Posts</h3>
                                <button onClick={this.handleNewArticlePostPopUp} className="newArticleBtn px-3"><i className="bi bi-plus"></i>New Article/Post</button>
                            </div>
                            {/* <div class="loader"></div> */}
                            <p id="resourceStatusChangeError" className="text-center text-danger">Something Went Wrong. Please try again</p>

                            {/* <div className="timeframeDiv mt-5">
                                    <span id="day" onClick={this.changeToDay} className="timeframe timeframeActive px-3"><button id="dayBtn">Day</button></span>
                                    <span id="week" onClick={this.changeToWeek} className="mx-3 timeframe "><button id="weekBtn">Week</button></span>
                                    <span id="month" onClick={this.changeToMonth} className="timeframe"><button id="monthBtn">Month</button></span>
                                </div> */}


                            <div className="mt-4 d-block justify-content-center ">
                                <table className="table">
                                    <thead >
                                        <tr className="">
                                            <th key={'articleID'} scope="col" className="colTitleArticle">Article ID <i className="bi bi-arrow-down-up arrowFilter"></i></th>
                                            <th key={'date'} scope="col" className="colTitleArticle">Date</th>
                                            <th key={'user'} scope="col" className="colTitleArticle">User <i className="bi bi-arrow-down-up arrowFilter"></i></th>
                                            <th key={'caption'} scope="col" className="colTitleArticle">Caption</th>
                                            <th key={'type'} scope="col" className="colTitleArticle">Resource Type</th>
                                            <th key={'status'} scope="col" className="colTitleArticle ps-4 ">Status <i className="bi bi-arrow-down-up arrowFilter"></i></th>
                                            <th key={'delete'} scope="col" className="colTitleArticle">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                        {this.state.showresources ?
                                            this.state.resourcesData.content.map((content, id) => (
                                                <tr className="tableRow">
                                                    <td key={content.id} className="tableDataArticleb text-start"><button className="tableDataBtn pt-2" onClick={this.handleShowPopUp.bind(this, content.id)}>{content.id.substring(0, content.id.indexOf('-'))}</button> </td>
                                                    <td key={content.id + content.dateCreated} className="tableDataArticle"><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn">{content.dateCreated}</button></td>
                                                    <td key={content.id + content.userFirstName} className="tableDataArticle"><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn">{content.userFirstName + " " + content.userLastName}</button></td>
                                                    <td key={content.id + content.title} className="tableDataArticle "><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn">{content.title}</button></td>
                                                    <td key={content.id + content.resourceTypeDescription} className="tableDataArticle"><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn ps-3">{content.resourceTypeDescription}</button></td>
                                                    <td className="">
                                                        <div className="dropdown pb-2 me-3">
                                                            <button className="btn  buttonDrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <span className="pendingSpan">{content.resourceStatusDescription}</span>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {this.state.showResourceStatusDropdown ?
                                                                    this.state.resourceStatusList.map((dropdownContent, id) => (
                                                                        <li onClick={this.updateResourceStatus.bind(this, content.id, dropdownContent.id)} key={dropdownContent.id}><a className="dropdown-item" href="#">{dropdownContent.description}</a></li>
                                                                    ))

                                                                    : <p className="text-danger"><i>Loading...</i></p>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td className="tableDataArticle"><button className="tableDataBtn"><i onClick={this.deleteResource.bind(this, content.id)} className="bi bi-trash3 deleteIcon"></i></button></td>
                                                </tr>
                                            ))

                                            : <tr >
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="d-flex pt-5 justify-content-center"><div class="loader"></div></td>
                                            </tr>
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="container mb-5 mt-3 text-end chevronDiv">
                            {this.state.currentPage > 1 ?
                                <button onClick={this.handleLeftChevronButton} className="chevronButton"><i className="bi bi-chevron-left border-none Chevronn"></i></button>
                                :
                                <button onClick={this.handleLeftChevronButton} className="chevronButton" disabled><i className="bi bi-chevron-left border-none Chevronn"></i></button>}

                            <span className="mx-1 chevronSpan">{this.state.currentPage} of {this.state.resourcesData.totalPages}</span>
                            {this.state.currentPage < this.state.resourcesData.totalPages ?
                                <button onClick={this.handleRightChevronButton} className="chevronButton chevronButtonRight"><i className="bi bi-chevron-right border-none Chevronn"></i></button>
                                :
                                <button disabled onClick={this.handleRightChevronButton} className="chevronButton chevronButtonRight " ><i className="bi bi-chevron-right border-none Chevronn "></i></button>
                            }
                        </div>

                        {this.state.showPopUp ?
                            <div id="adDetailId " className="adDetailsDiv">
                                <div className="">

                                    {this.state.showResourceDetails ?
                                        <div>
                                            <div className="d-flex justify-content-between container pt-3">
                                                <img className="popupCircleImg ms-3" src={this.state.resourceDetails.userProfileImageUrl}></img>
                                                <button className="cancelPopupBtn" onClick={this.handleClosePopUp}><i className="bi bi-x"></i></button>
                                            </div>

                                            <div className="container ms-3 mt-3">
                                                <h6 className="adPoster">{this.state.resourceDetails.userFirstName + " " + this.state.resourceDetails.userLastName}</h6>
                                                <p className=" adsLocation mb-4 ">{this.state.resourceDetails.dateCreated}</p>
                                                <div className="heroPhotoUrlDiv">
                                                    <img src={this.state.resourceDetails.heroPhotoUrl} className="img-fluid adsBigImage" alt="..."></img>
                                                </div>


                                                <div className="mt-3">
                                                    <h2 className="adsAnimalBreed">{this.state.resourceDetails.title}</h2>
                                                    <p className="adsAboutText preserveSpaces">{this.state.resourceDetails.bodyText}</p>
                                                </div>

                                                <div className="mt-4 row">
                                                    {this.state.resourceDetails.tags.map((tagss, id) => (
                                                        <div className="tagsDiv col-3 mb-4">
                                                            <span className="adsFilter">{tagss.description}</span>
                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>

                                        : <div className="d-flex justify-content-center">
                                            <div class=" loader"></div>
                                        </div>
                                    }

                                </div>
                            </div>
                            : null
                        }


                        {this.state.showNewArticlePostPopUp ?
                            <div id="adDetailId " className=" newArticlePostDiv">
                                <div className="">

                                    <div className="d-flex justify-content-between container pt-3">
                                        <h5 className="pt-3 ms-3 newArticlePostText">New Article/Post</h5>
                                        <button className="cancelPopupBtn" onClick={this.handleCloseNewArticlePostPopUp}><i className="bi bi-x"></i></button>
                                    </div>
                                    <div className="container ms-3 mt-3">
                                        <h6 className="addImageOutter inputHeader">Add Image</h6>

                                        <div className="mt-3 ">
                                            <form onSubmit={this.handleSubmit} >
                                                <div className="outerAdImgDiv">
                                                    <div className="form-group mt-4 ms-4 innerAdImgDiv">
                                                        <div className="custom-file">
                                                            <input onChange={this.handleFileChange} name="image" type="file" className=" custom-file-input d-none" id="imageInput" accept="image/*" disabled={this.state.disableNewResourcesForm}/>
                                                            <div className="text-center d-flex justify-content-center">
                                                                <div className="addImagePlusDiv ">
                                                                    <label className="custom-file-label text-center" htmlFor="imageInput"><i className="bi bi-plus addImage"></i></label>
                                                                </div>

                                                            </div>
                                                            <div className="addImageTextBelowDiv text-center pt-3">
                                                                <p className="adImageInnerP">Add Image</p>
                                                                <p className="adImageInnerWarning">Not more than 2mb</p>
                                                                <div id="fileIndicator">{selectedFile ? selectedFile.name : "no Image selected"}</div>
                                                                {this.state.successImageUpload ?
                                                                    null
                                                                    : <p className="text-danger pt-3">Error uploading Image</p>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="mt-4">
                                                    <div>
                                                        <label htmlFor="title" className="form-label inputHeader">Give it a title</label>
                                                        <input name="title" onChange={this.handleInputChange} value={title} type="text" className="form-control articleInputFeild" id="title" aria-describedby="emailHelp" placeholder="Write Title" disabled={this.state.disableNewResourcesForm}/>
                                                        <p className="inputBelowWarning">Not more than 50 words</p>
                                                    </div>

                                                    <div>
                                                        <label htmlFor="title" className="form-label inputHeader">Write Description</label>
                                                        <textarea onChange={this.handleInputChange} value={description} name="description" rows="3" type="text" className="form-control articleInputFeild" id="title" aria-describedby="emailHelp" placeholder="Write Title" disabled={this.state.disableNewResourcesForm}/>
                                                        <p className="inputBelowWarning">Not more than 300 words</p>
                                                    </div>

                                                    <div className="mb-2">
                                                        <label htmlFor="title" className="form-label inputHeader">Resource Type</label>
                                                        <select onChange={this.handleInputChange} value={type} name="type" className="form-select" aria-label="Default select example" disabled={this.state.disableNewResourcesForm}>
                                                            <option >select...</option>
                                                            {this.state.showResourceType ?
                                                                this.state.resourceTypeList.map((type, id) => (
                                                                    <option value={type.id}>{type.description}</option>
                                                                ))
                                                                : <option className="text-danger">Loading...</option>
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="my-4">
                                                        <label htmlFor="title" className="form-label inputHeader">Resource Status</label>
                                                        <select onChange={this.handleInputChange} value={this.state.newResourceStatus} name="newResourceStatus" className="form-select" aria-label="Default select example" disabled={this.state.disableNewResourcesForm}>
                                                            <option >select...</option>
                                                            {this.state.showResourceStatusDropdown ?
                                                                this.state.resourceStatusList.map((dropdownContentt, id) => (
                                                                    <option value={dropdownContentt.id}>{dropdownContentt.description}</option>
                                                                ))
                                                                : <option className="text-danger"><i>Loading...</i></option>
                                                            }
                                                        </select>
                                                    </div>

                                                    <label htmlFor="title" className="form-label inputHeader mt-2">Add Tags</label>
                                                    <div className="mt-2">
                                                        {this.state.showTags ?
                                                            <div className="row">
                                                                {/* {console.log(tagsData)} */}
                                                                {tagsData.map((description, id) => (
                                                                    <div className="tagsDiv col-3 mb-4" >
                                                                        {/* <input key={description.id} type="checkbox" onChange={this.handleInputChange} value={tags} name="tags" className="btn-check articleFilter" id={description.description} autocomplete="off" />
                                                                        <label class="btn articleFilterBtn" for={description.description}>{description.description}</label> */}
                                                                        <span disabled={this.state.disableNewResourcesForm} value={description.id} onClick={this.handleNewResourceTags} className="articleFilter tagssss">{description.description}</span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            : <h1 className="fs-5 text-danger">No Tags Found, Try again Later</h1>
                                                        }


                                                    </div>

                                                </div>

                                                <div className="d-flex justify-content-center">
                                                    <button id="addArticlePostBtnn" className={` mt-5 addArticlePostBtn btn ${this.state.isFilled && this.state.newResourceImageisFilled && this.state.newResourceTagisFilled ? 'filled' : ''}`} disabled={!this.state.isFilled || !this.state.newResourceImageisFilled || !this.state.newResourceTagisFilled || !this.state.successImageUpload}>Save</button>
                                                    {/* {console.log(this.state.newResourceTagisFilled)} */}
                                                </div>

                                            </form>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            : null
                        }

                    </main>
                </div>
            </div>
        )
    }
}

export default ManageArticlesPosts;