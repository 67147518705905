import { Component } from "react";
import petmiLogo from '../images/petmiLogo.png';
import adsIcon from '../images/adsIcon.png';
import articleIcon from '../images/articlesIcon.png';
import logoutIcon from '../images/logoutIcon.png';
import sideDog from '../images/sideDog.png';
import notificationIcon from '../images/notificationIcon.png';
import personAvatar from '../images/personAvatar.png';
import dogRound from "../images/dogRound.png";
import adsBigDog from "../images/adsBigDog.png";
import { details } from "./Configs";

class ManageAds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            adsPage: 0,
            adsData: [],
            permanentAdsData: [],
            showAds: false,
            showAdsStatusDropdown: false,
            dontRunFetchAds: false,
            currentPage: 1,
            adDetails: [],
            showAdDetails: false,
            searchValue: '',
            isSearchValueChanged: false,
            searchData: [],
            adsStatusList: [],
        };
    }

    componentDidMount() {
        const storedUser = JSON.parse(localStorage.getItem(('user')));
        if (!storedUser) {
            window.location.href = '/';
        }
        this.fetchAdsDropdown();
    }

    fetchAdsList = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/ads?page=${this.state.adsPage}&pageSize=10`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const adsList = jsonResponse.data;
            // console.log(jsonResponse);
            this.setState({ adsData: adsList });
            this.setState({ permanentAdsData: adsList });
            this.setState({ dontRunFetchAds: true })

            if (response.ok) {
                return this.setState({ showAds: true })
            } else {
                return this.setState({ showAds: false })
            }
        } catch (error) {
            this.setState({ dontRunFetchAds: true })
            return this.setState({ showAds: false })

        }
    };

    fetchAdDetails = async (adId) => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/ads/${adId}`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const adsList = jsonResponse.data;
            console.log(jsonResponse);
            this.setState({ adDetails: adsList }, () => {
                if (response.ok) {
                    return this.setState({ showAdDetails: true })
                } else {
                    return this.setState({ showAdDetails: false })
                }
            });



        } catch (error) {
            return this.setState({ showAdDetails: false })
        }
    };

    fetchSearchList = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/ads/search?page=${this.state.adsPage}&pageSize=10&term=${this.state.searchValue}`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const searchList = jsonResponse.data;
            // console.log(jsonResponse);
            // this.setState({ dontRunFetchAds: true })

            if (response.ok) {
                if (this.state.isSearchValueChanged) {
                    this.setState({ adsData: searchList });
                } else {
                }

            } else {
                // console.log("response not ok")
            }
        } catch (error) {
            console.log(error)

        }
    };


    fetchAdsDropdown = async () => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/ads/states`;


            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
            });
            const jsonResponse = await response.json();
            const statusList = jsonResponse.data;
            // console.log(jsonResponse);
            this.setState({ adsStatusList: statusList });

            if (response.ok) {
                return this.setState({ showAdsStatusDropdown: true })
            } else {
                return this.setState({ showAdsStatusDropdown: false })
            }
        } catch (error) {
            console.log(error)

        }
    };


    updateAdStatus = async (adId, statusId) => {
        try {
            const apiUrl = `http://${details.baseUrl}/console/ads/${adId}`;


            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${details.Authorization}`
                },
                body: statusId
            });
            const jsonResponse = await response.json();
            const newAdStatus = jsonResponse.data;
            console.log(jsonResponse);
            if (response.ok) {
                document.getElementById("adStatusChangeError").style.display = 'none';
                window.location.href = '/manage/ads';
            } else {
                setTimeout(() => {
                    document.getElementById("adStatusChangeError").style.display = 'block';
                }, 1000);
            }



        } catch (error) {
            setTimeout(() => {
                document.getElementById("adStatusChangeError").style.display = 'block';
            }, 1000);
        }
    };

    handleShowPopUp = (adId) => {
        this.setState({ showPopUp: true });
        this.fetchAdDetails(adId);
        // document.body.style.overflow = 'hidden';
    };

    handleClosePopUp = () => {
        // document.body.style.overflow = ''
        this.setState({ showPopUp: false });
        window.location.href = '/manage/ads';
    }

    handleLogout = () => {
        localStorage.removeItem('user');
        window.location.href = '/';
    }

    handleLeftChevronButton = () => {
        this.setState({ currentPage: this.state.currentPage - 1 })
        this.setState({ adsPage: this.state.adsPage - 1 }, () => {
            this.fetchAdsList();
        })

    }

    handleRightChevronButton = () => {
        this.setState({ currentPage: this.state.currentPage + 1 })
        this.setState({ adsPage: this.state.adsPage + 1 }, () => {
            this.fetchAdsList();
        })

    }

    handleSearchInputChange = (e) => {
        const newValue = e.target.value;
        this.setState({
            searchValue: newValue,
        }, () => {
            newValue.length >= 1 ?
                this.setState({
                    isSearchValueChanged: true,
                }, () => { this.fetchSearchList(); })

                : this.setState({
                    adsData: this.state.permanentAdsData,
                    isSearchValueChanged: false,

                })


        });
    }


    handleCloseSearchResult = () => {
        this.setState({
            adsData: this.state.permanentAdsData,
            isSearchValueChanged: false,
            searchValue: '',

        })
    }


    // THIS IS THE CODE FOR FILTERING BETWEEN WEEK, MONTH AND DAY. MIGHT NEED IT LATER
    // changeToDay = () => {
    //     var innerButton = document.getElementById('dayBtn');

    //     // Remove the timeframeActive class from other spans
    //     var removeClass = document.querySelector('.timeframeActive');
    //     removeClass.classList.remove('timeframeActive');

    //     var parentElement = innerButton.parentElement;
    //     parentElement.classList.add('timeframeActive');
    // }

    // changeToWeek = () => {
    //     var innerButton = document.getElementById('weekBtn');

    //     // Remove the timeframeActive class from other spans
    //     var removeClass = document.querySelector('.timeframeActive');
    //     removeClass.classList.remove('timeframeActive');

    //     var parentElement = innerButton.parentElement;
    //     parentElement.classList.add('timeframeActive');
    // }

    // changeToMonth = () => {
    //     var innerButton = document.getElementById('monthBtn');

    //     // Remove the timeframeActive class from other spans
    //     var removeClass = document.querySelector('.timeframeActive');
    //     removeClass.classList.remove('timeframeActive');

    //     var parentElement = innerButton.parentElement;
    //     parentElement.classList.add('timeframeActive');
    // }


    render() {
        const { showPopUp } = this.state;
        const { adsPage } = this.state;
        const storedUser = JSON.parse(localStorage.getItem(('user')));
        if (!storedUser) {
            window.location.href = '/';
        }
        if (!this.state.dontRunFetchAds) {
            this.fetchAdsList();
        }
        return (
            <div className=" ">
                <div className="row g-0 ">
                    {/* Sidebar  */}
                    <nav id="sidebar" className={`col-2 d-md-block border border-1 comp-two ${showPopUp ? 'manageAdsDiv' : ''}`} >
                        <div className="text-center mt-3">
                            <img src={petmiLogo} alt="Logo" className="img-fluid  petmiLogo2 ms-3 mt-4 " />
                        </div>

                        <ul className="nav flex-column mt-3 mb-5">
                            <li className="nav-item w-100 activeSideLink">
                                <a className="nav-link active sideLink" href="/manage/ads">
                                    <img src={adsIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Ads
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link sideLink" href="#">
                                    Posts
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link sideLink" href="/manage/resources">
                                    <img src={articleIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Articles/Posts
                                </a>
                            </li>
                            <li className="nav-item mb-5">
                                <a className="nav-link sideLink" href="#" onClick={this.handleLogout}>
                                    <img src={logoutIcon} alt="Ads" className="img-fluid adsIcon me-3" />
                                    Logout
                                </a>
                            </li>
                        </ul>

                        <img src={sideDog} alt="Bottom Image" className="img-fluid ms-1 mt-5 pt-5" />
                    </nav>

                    {/* <!-- Page content --> */}
                    <main className="col-9 mt-3 ">
                        <div className={`row gx-5  navRow ms-3 comp-two ${showPopUp ? 'manageAdsDiv' : ''}`}>
                            <div className="col-5 mt-3">
                                <div className="input-group">
                                    <input value={this.state.searchValue} onChange={this.handleSearchInputChange} type="text" className="form-control searchInput" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                                    <button className="btn btn-outline-secondary searchIconBtn pe-4" type="button" id="search-addon">
                                        <i className="bi bi-search searchIcon"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-6 d-flex justify-content-end ps-4 mt-3 outterRightNavDiv">
                                <div className="notificationIconDiv me-5">
                                    <img src={notificationIcon} alt="Notification Icon" className="img-fluid notificationIcon" />
                                    <span className=" position-relative">
                                        <i className="bi notificationDot bi-dot notigi position-absolute top-0 start-100 translate-middle pe-2 pt-2"></i>
                                    </span>
                                </div>

                                <div className="rightNav mt-4 ">
                                    <img src={storedUser.profileImageUrl} alt="Logout Icon" className="img-fluid personAvatar" />
                                    <div className="rigtNavInnerDiv" >
                                        <span className="personName">{storedUser.firstName}</span>
                                        <span className="personRole">Admin</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`comp-two ${showPopUp ? 'manageAdsDiv' : ''}`}>
                            <hr className="navLine " />
                        </div>

                        <div className={`container ms-5 comp-two ${showPopUp ? 'manageAdsDiv' : ''}`}>
                            <div className="d-flex justify-content-between">
                                <h3>Ads</h3>
                                {this.state.isSearchValueChanged ?
                                    <button onClick={this.handleCloseSearchResult} className="closeSearchResult"><i class="bi bi-x-lg"></i></button>
                                    : null
                                }

                            </div>
                            <p id="adStatusChangeError" className="text-center text-danger">Something Went Wrong. Please try again</p>

                            {/* <div className="timeframeDiv mt-5">
                                <span id="day" onClick={this.changeToDay} className="timeframe timeframeActive px-3"><button id="dayBtn">Day</button></span>
                                <span id="week" onClick={this.changeToWeek} className="mx-3 timeframe "><button id="weekBtn">Week</button></span>
                                <span id="month" onClick={this.changeToMonth} className="timeframe"><button id="monthBtn">Month</button></span>
                            </div> */}


                            <div className="mt-4 d-block justify-content-center ">
                                <table className="table">
                                    <thead >
                                        <tr className="">
                                            <th scope="col" className="colTitle">Ad ID <i className="bi bi-arrow-down-up arrowFilter"></i></th>
                                            <th scope="col" className="colTitle">Date</th>
                                            <th scope="col" className="colTitle">Title</th>
                                            <th scope="col" className="colTitle">User <i className="bi bi-arrow-down-up arrowFilter"></i></th>
                                            <th scope="col" className="colTitle">Status <i className="bi bi-arrow-down-up arrowFilter"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                        {this.state.showAds ?
                                            this.state.adsData.content.map((content, id) => (
                                                <tr key={content.id} className="tableRow">
                                                    <td key={content.id + content.id} className="tableData"><button className="tableDataBtn" onClick={this.handleShowPopUp.bind(this, content.id)}>{content.id.substring(0, content.id.indexOf('-'))}</button> </td>
                                                    <td key={content.id + content.dateCreated} className="tableData"><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn">{content.dateCreated}</button></td>
                                                    <td key={content.id + content.title} className="tableData"><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn">{content.title}</button></td>
                                                    <td key={content.id + content.userFirstName} className="tableData"><button onClick={this.handleShowPopUp.bind(this, content.id)} className="tableDataBtn">{content.userFirstName + " " + content.userLastName}</button></td>
                                                    <td className="">
                                                        <div className="dropdown pb-2">
                                                            <button className="btn  buttonDrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <span className="pendingSpan">{content.resourceStatusDescription}</span>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {this.state.showAdsStatusDropdown ?
                                                                    this.state.adsStatusList.map((dropdownContent, id) => (
                                                                        <li onClick={this.updateAdStatus.bind(this, content.id, dropdownContent.id)} key={dropdownContent.id}><a className="dropdown-item" href="#">{dropdownContent.description}</a></li>
                                                                    ))

                                                                    : <p className="text-danger">Loading...</p>
                                                                }

                                                                {/* <li><a className="dropdown-item" href="#">Delete</a></li> */}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))

                                            : <tr >
                                                <td></td>
                                                <td className="d-flex pt-5 justify-content-center"><div class="loader"></div></td>
                                            </tr>
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="container mb-5 mt-3 text-end chevronDiv ">
                            {this.state.currentPage > 1 ?
                                <button onClick={this.handleLeftChevronButton} className="chevronButton"><i className="bi bi-chevron-left border-none Chevronn"></i></button>
                                :
                                <button onClick={this.handleLeftChevronButton} className="chevronButton" disabled><i className="bi bi-chevron-left border-none Chevronn"></i></button>}

                            <span className="mx-1 chevronSpan">{this.state.currentPage} of {this.state.adsData.totalPages}</span>
                            {this.state.currentPage < this.state.adsData.totalPages ?
                                <button onClick={this.handleRightChevronButton} className="chevronButton chevronButtonRight"><i className="bi bi-chevron-right border-none Chevronn"></i></button>
                                :
                                <button disabled onClick={this.handleRightChevronButton} className="chevronButton chevronButtonRight " ><i className="bi bi-chevron-right border-none Chevronn "></i></button>
                            }
                        </div>

                        {this.state.showPopUp ?
                            <div>
                                <div id="adDetailId " className="adDetailsDiv">

                                    <div className="">
                                        {this.state.showAdDetails ?
                                            <div>
                                                <div className="d-flex justify-content-between container pt-3">
                                                    {console.log(this.state.adDetails)}
                                                    <img className="popupCircleImg ms-3" src={this.state.adDetails.userProfileImageUrl}></img>
                                                    <button className="cancelPopupBtn" onClick={this.handleClosePopUp}><i className="bi bi-x"></i></button>
                                                </div>
                                                <div className="container ms-3 mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <h6 className="adPoster">{this.state.adDetails.userFirstName + " " + this.state.adDetails.userLastName}</h6>
                                                        <h2 className="adsPrice">₦{this.state.adDetails.price}</h2>
                                                    </div>
                                                    <p className="adType ">{this.state.adDetails.title}</p>
                                                    <p className=" adsLocation "><i className="bi bi-geo-alt text-danger"></i> {this.state.adDetails.stateName}</p>
                                                    <div className="heroPhotoUrlDiv">
                                                        <img src={this.state.adDetails.heroPhotoUrl} className="img-fluid adsBigImage" alt="..."></img>
                                                    </div>

                                                    <p className="overlay-text">FOR SALE</p>

                                                    <div className="mt-3">
                                                        <h2 className="adsAnimalBreed">{this.state.adDetails.title}</h2>
                                                        <p className="adsAboutText preserveSpaces">{this.state.adDetails.bodyText}</p>
                                                    </div>

                                                    <div className="mt-4 row">
                                                        {this.state.adDetails.tags.map((tagss) => (
                                                            <div className="tagsDiv col-3 mb-4">
                                                                <span className="adsFilter">{tagss.description}</span>
                                                            </div>
                                                        ))}


                                                    </div>
                                                </div>
                                            </div>

                                            :
                                            <div className="d-flex justify-content-center">
                                                <div class=" loader"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            : null
                        }
                    </main>
                </div>
            </div>
        );
    }
}

export default ManageAds;